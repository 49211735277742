<template>
  <el-card id="clientsCharts">
    <header>
      <h1 style="text-align: center;">数据统计</h1>

    </header>
    <hr>
    <main>
      <!-- <div class="content">
        <div class="client-type" v-for="v in infoData" :key="v.id">
          <span> {{ v.value }}</span>
          <p>{{ v.name }}</p>
        </div>
      </div> -->
      <!-- <el-button @click="logForm" type="success" size="mini" icon="el-icon-refresh">查询</el-button> -->

      <div id="khChartB" style="width: 60%; height: 400px"></div>

      <div id="chartPieB" style="width: 40%; height: 400px"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneFindUser, phoneTimeAll, phoneCountAddStatus } from "@/api/phone";
export default {
  props: ['adminForm'],//接收psMsg值
  data() {
    return {
      timer: null,
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      monthTime: [],
      infoData: [],
      departId: null,
      userId: null,
      underDepart: [], // 部门
      underUser: [], // 员工
      roleName: null,
      nameArr: [
        {
          name: "待续企业数",
          title: "y_money_count",
          value: 0,
          color: '#984B10'
        },
        {
          name: "预计续费金额",
          title: "y_money",
          value: 0,
          color: '#7C1010'
        },
        {
          name: "不活跃坐席数",
          title: "stop_count",
          value: 0,
          color: '#2C294A'
        },
        {
          name: "坐席数",
          title: "seat_count",
          value: 0,
          color: '#082D84'
        },
        {
          name: "锁定企业数",
          title: "user_stop_count",
          value: 0,
          color: '#074A4F'
        },
        {
          name: "企业数",
          title: "user_count",
          value: 0,
          color: '#06406D'
        },
        {
          name: "个人套餐",
          title: "gr_seatMoney",
          value: 0,
          color: '#045A41'
        },
        {
          name: "公司套餐",
          title: "gs_seatMoney",
          value: 0,
          color: '#094B11'
        },
        {
          name: "账户余额",
          title: "gs_money",
          value: 0,
          color: '#6A8D2B'
        },
      ]
    };
  },
  watch: {
    //参数一：改变后的值
    //参数二：改变前的值
    adminForm(newvalue, oldvalue) {
      console.log("oldvalue", this.adminForm);

      Object.keys(this.adminForm).forEach((key, index) => {
        this.nameArr.forEach((v, i) => {
          if (v.title == key) {
            if (this.adminForm[key]) {
              v.value = this.adminForm[key]
              // this.infoData.push({ title: key, value: this.adminForm[key], name: this.nameArr[index] })
            }
            // this.infoData.push({ title: key, value: 0, name: this.nameArr[index] })
          }

        })

      })
      console.log(" this.infoData", this.infoData);
      setTimeout(() => {
        this.getList();
      }, 1000)
    }
  },
  mounted() {
  },
  async created() {
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id");
    // let rootDate = new Date().toLocaleDateString()
    // this.monthTime = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
    // // this.render("/daystatusAll");

    // // if (this.roleName == "管理员" || this.roleName == "企业") {
    // if (this.roleName == "组长" || this.roleName == "企业") {
    //   this.getAllUser();
    // }
    // // this.getAllUser();
  },
  methods: {
    logForm() {
      console.log("this.adminForm", this.adminForm);
      console.log(" this.infoData", this.infoData);
      this.drawPieChart()
    },
    // 获取所有部门和员工
    getAllUser() {
      let res = phoneFindUser();
      return res.then((res) => {
        let data = res.data.data;
        // console.log("处理数据", data);
        // 处理数据
        // 部门
        this.underDepart = data.depart.map((v, i) => {
          // console.log("部门每项", v);
          return { id: i + 1, name: v.departName, value: v.id };
        });
        // 人员
        this.underUser = data.user.map((v, i) => {
          return { id: i + 1, name: v.realname, value: v.id };
        });
        // console.log("所有用户信息", res.data);
        return res.data;
      });
      // departId
      // userId
      // depart_name 部门名字
    },

    drawPieChart() {
      // 右边饼图
      let chartPie = echarts.init(document.getElementById("chartPieB"));
      chartPie.setOption({
        title: {
          x: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          // orient: "vertical",
          left: "top",
          data: this.nameArr.map((v) => {
            return v.name
          }),
        },
        series: [
          {
            type: "pie",
            radius: ["20%", "40%"],
            center: ["50%", "50%"],
            data: this.nameArr,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              formatter: " {b|{b}：}{c}  占{per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                },
              },
            },
          },
        ],
      });

      // 左边柱状图
      this.drawleftChart();
    },
    addEl() {
      let newArr = [...this.nameArr]
      newArr.reverse()
      return newArr.map((v) => {
        return `
                  <div class="item" style="display: flex;align-items: center;margin-bottom: -18px;">
                    <div class="dot" style="width: 8px;height: 8px;border-radius: 8px;margin-right: 8px;background-color: ${v.color}"></div>
                    <div class="text" style="display: flex;align-items: center;justify-content: space-between;min-width: 100px;"><span style="margin-right: 10px;">${v.name}:</span> <span>${v.value}</span></div>
                  </div>
                `
      })
    },
    drawleftChart() {
      let chartPie = echarts.init(document.getElementById("khChartB"));
      chartPie.setOption({

        color: ["#3278FE"],

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return `
              <div class="statisticsBox">
                ${this.addEl()}

               </div>
              `;
            // 鼠标移上显示的文字
            //1.span是小蓝点
            //2.后边是显示的文字，一定要加[0](如果想知道为啥log一下看看数据console.log(params))
          }
        },
        legend: {
          // orient: "vertical",
          left: "top",
          data: this.nameArr.map((v) => {
            return v.name
          }),
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.nameArr.map((v) => {
            return v.name
          }),
        },
        series: [
          {
            type: "bar",
            data: this.nameArr,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#3278FE",
              },
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
      });
    },
    drawCharts() {
      this.drawPieChart();
    },
    // 展示
    getList() {


      this.drawCharts();

      // if (this.monthTime) {
      //   this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[0]);
      //   this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[1]);
      // }
      // let params = {
      //   departId: this.departId,
      //   userId: this.userId,
      //   sta: this.sta,
      //   end: this.end,
      // };
      // let res = phoneCountAddStatus(params);
      // res.then((res) => {
      //   let data = [
      //     "一般客户",
      //     "意向客户",
      //     "无意向客户",
      //     "成交客户",
      //     "黑名单",
      //     "无状态",
      //     "未接通",
      //   ];

      //   let arr = [];
      //   for (let i = 0; i <= 6; i++) {
      //     arr.push({ id: i, name: data[i], value: res.data.data[i] || 0 });
      //   }

      //   this.infoData = arr.sort(function (a, b) {
      //     return b.value - a.value;
      //   });
      //   this.infoData = [
      //     {
      //       id: 0,
      //       name: "一般客户",
      //       value: 0
      //     }
      //   ]

      //   this.drawCharts();
      // });

    },
    // 搜索
    searchBtn() {
      this.getList();
    },
    // 重置
    reset() {
      this.userId = null;
      this.departId = null;
      // this.render("/daystatusAll");
      this.monthTime = null;
      this.sta = null;
      this.end = null;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
  updated: function () {
    this.drawCharts();
  },
};
</script>

<style lang="less" scoped>
.statisticsBox {
  display: inline-block;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .22);

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 8px;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100px;
    }
  }
}

#clientsCharts {


  header {
    .search {
      display: flex;
      // margin-top: 20px;
      // flex-direction: column;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    display: flex;
    justify-content: space-between;

    .content {
      width: 50%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding: 30px;
      box-sizing: border-box;
      text-align: center;

      .client-type {
        text-align: center;
        width: 158px;
        padding: 20px 0;
        box-sizing: border-box;
      }

      .client-type>span {
        font-size: 32px;
      }

      .client-type>p {
        font-size: 18px;
      }
    }
  }
}
</style>
/**
 * 上月创建坐席等
 *
 */

import request from "@/utils/request";

// 查询
export const oldSumSeatRate = (data) => {
  return request({
    method: "post",
    url: "/seat-phone/oldSum-seat-rate",
    data,
  });
};

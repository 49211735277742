<template>
    <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        // 获取容器元素
        const container = this.$refs.chartContainer;
  
        // 创建初始数据数组
        const data = [10, 8, 6, 4, 2];
        
        // 创建初始标签数组
        const labels = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  
        // 创建初始柱状图
        const chart = echarts.init(container);
        chart.setOption({
          title: {
            text: '动态排序柱状图'
          },
          xAxis: {
            type: 'category',
            data: labels
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data,
            type: 'bar',
            color: 'rgba(75, 192, 192, 1)'
          }]
        });
  
        // 模拟动态排序
        setTimeout(() => {
          data.sort((a, b) => b - a);
          chart.setOption({
            series: [{
              data
            }]
          });
        }, 3000);
      }
    }
  }
  </script>
<template>
  <el-card id="clientsRanking">
    <header>
      <!-- <h1>意向客户排名</h1> -->
      <div class="search">
        <!-- <div class="right">
         <el-button @click="getList('')" type="primary" size="mini"
            >今日</el-button
          >
          <el-button @click="getList(7)" type="primary" size="mini"
            >本周</el-button
          >
          <el-button @click="getList(30)" type="primary" size="mini"
            >当月</el-button
          >
        </div> -->
        <div class="left">
          <div class="left-wrap">
            <span>选择时间范围</span>
            <el-date-picker v-model="monthTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini" :picker-options="pickerOptions">
            </el-date-picker>
            <el-button style="margin-left: 30px" type="success" icon="el-icon-search" @click="searchBtn" size="mini"
              class="searchBtn">查找</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="reset" size="mini" class="reset">重置</el-button>
          </div>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-empty v-if="showErr" :description="message"></el-empty>
      <div v-else id="homeCharts" style="height: 400px; width: 100%"></div>
    </main>
  </el-card>
</template>


<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import * as echarts from "echarts";
import { phoneRoleIntent, phoneCountAddStatus } from "@/api/phone";
export default {
  data() {
    return {
      timer: null,
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      monthTime: [],
      showErr: true,
      message: "",
      homeCharts: null,
      titleArr: [],
      valueArr: [],
      roleName: "",
      parentId: "",
      id: "",
    };
  },
  async created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.id = localStorage.getItem("id");
    let res = await this.getList();
    let res1 = await this.drawColumnChart();
  },
  methods: {

    // 搜索
    searchBtn() {
      this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[0]);
      this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[1]);
      this.getList();
    },
    // 重置
    async reset() {
      this.monthTime = null;
      this.sta = null;
      this.end = null;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 获取数据
    getList() {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        // console.log("连续在两秒内触发只生效一次");
        let userId = "";
        if (this.roleName == "企业") {
          userId = this.id;
        } else {
          userId = this.parentId;
        }
        let params = {
          sta: this.sta,
          end: this.end,
          userId,
        };
        let res = phoneRoleIntent(params);
        return res.then((res) => {
          let data = res.data.data;
          if (!data) {
            this.showErr = true;
            this.message = res.data.message;
          } else {
            this.showErr = false;
          }
          this.titleArr = [];
          this.valueArr = [];
          let newArr = data.map((v) => {
            return { title: v.realname, value: v[1] };
          });
          newArr.sort(function (a, b) {
            return b.value - a.value;
          });
          newArr.forEach((v) => {
            this.titleArr.push(v.title);
            this.valueArr.push(v.value);
          });
          this.$nextTick(() => {
            this.drawColumnChart();
          });
        });
        this.timer = null
      }, 500);
    },
    drawColumnChart() {
      console.log("标题", this.titleArr);
      console.log("值", this.valueArr);
      this.homeCharts = echarts.init(document.getElementById("homeCharts"));
      this.homeCharts.setOption({
        title: { text: "意向客户排名" },
        color: ["#3278FE"],
        tooltip: {},
        xAxis: {
          //   interval: 0, //去除间隙  刻度间隔为0
          data: this.titleArr,
          name: "姓名",
        },

        // yAxis: { minInterval: 1 },
        yAxis: [
          {
            type: "value",
            name: "意向客户数",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: true,
            },
          },
          // data: this.valueArr,
        ],
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            // debugger;
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 4; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于3,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            name: "意向客户",
            type: "bar",
            barMaxWidth: '80',
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#3278FE",
              },
              formatter: function (params) {
                return params.value + "位客户";
              },
            },
            animationDelay: function (idx) {
              return idx * 2;
            },
            emphasis: {
              focus: "series",
            },
            data: this.valueArr,
          },
        ],
        animationEasing: "elasticOut",
        animationDelayUpdate: function (idx) {
          return idx * 2;
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
#clientsRanking {
  header {
    .search {

      // .right {
      //   margin-top: 20px;
      //   text-align: right;
      //   display: flex;
      //   /* justify-content: space-between; */
      //   justify-content: flex-end;
      //   align-items: center;
      //   height: 100%;
      // }
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>
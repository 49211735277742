<template>
  <div style="height: 100%;position: relative;">
    <el-card class="container">
      <header v-if="roleName != '管理员'">
        <div class="c2 zxxx">
          <!-- <h3>坐席信息</h3>
          <div>
            <img src="../../assets/images/c2.png" alt="" />
          </div> -->
          <p class="company-name" v-if="roleName != '企业'">
            员工名称：<span>{{ realname }}</span>
          </p>
          <p class="company-name" v-if="roleName == '企业'">
            坐席额度：<span>{{ seatSumCount }}</span>
          </p>
          <p class="company-name" v-if="roleName == '企业'">
            账户分钟数：<span>{{ money }}</span>
          </p>
          <p class="company-name" v-if="roleName != '管理员'">
            套餐分钟数：<span>{{ seatMoney }}</span>
          </p>
          <p class="company-name" v-if="roleName != '企业'">
            登录账户：<span>{{ ms_name }}</span>
          </p>
          <!-- adminInfo -->
          <!-- <p class="company-name" v-if="roleName == '管理员'">
            坐席总数量：<span>{{ adminInfo.seatCount }}</span>
          </p>
          <p class="company-name" v-if="roleName == '管理员'">
            正常企业数量：<span>{{ adminInfo.userCount }}</span>
          </p>
            <p class="company-name" v-if="roleName == '管理员'">
            个人余额合计：<span>{{ adminInfo.gr_seatMoney }}</span>
          </p>
          <p class="company-name" v-if="roleName == '管理员'">
            公司余额合计：<span>{{ adminInfo.gs_money }}</span>
          </p>
          <p class="company-name" v-if="roleName == '管理员'">
            公司套餐合计：<span>{{ adminInfo.gs_seatMoney }}</span>
          </p> -->
          <!-- <p class="company-name" v-if="roleName == '人员' || roleName == '组长'">
            公司名称：<span>{{ companyName }}</span>
          </p>
          <p class="company-name" v-if="roleName == '企业'">
            公司名称：<span>{{ realname }}</span>
          </p> -->
        </div>

        <div class="t2 zxxx" v-if="roleName == '企业'">
          <!-- <h3>
            通话情况
            <span style="color: #626262; font-size: 12px"
              >(数据统计延迟30分钟)</span
            >
          </h3>
          <div>
            <img src="../../assets/images/e2.png" alt="" />
          </div>
        -->
          <p class="company-name">
            接通数：<span>{{ sumCount }}</span> 次
          </p>
          <p class="company-name">
            今日通话：<span>{{ count }}</span> 次
          </p>
          <p class="company-name">
            通话时长：<span>{{ minute }}</span> 分钟
          </p>
        </div>
        <div class="t2 zxxx" v-else>
          <!-- <h3>
            通话情况
            <span style="color: #626262; font-size: 12px"
              >(数据统计延迟30分钟)</span
            >
          </h3>
          <div>
            <img src="../../assets/images/e2.png" alt="" />
          </div> -->
          <p class="company-name">
            接通数：<span>{{ user_count }}</span> 次
          </p>
          <p class="company-name">
            今日通话：<span>{{ user_sumCount }}</span> 次
          </p>
          <p class="company-name">
            通话时长：<span>{{ user_minute }}</span> 分钟
          </p>
        </div>
        <!-- <h3>短信相关</h3>
        <div>
          <img src="../../assets/images/f.png" alt="" />
        </div> -->
        <!-- <div class="d2 zxxx">
          <p class="company-name">
            短信费率：<span>{{ rate }}</span>
          </p>
          <p class="company-name">
            短信月租：<span>{{ seatRate }}</span>
          </p>
          <p class="company-name">
            当前剩余短信次数：<span>{{ note_count }}</span> 次
          </p>
          <p class="company-name">
            当前剩余短信套餐次数：<span>{{ s_seatCount }}</span> 次
          </p>
        </div> -->
        <div class="x2 zxxx">
          <!-- <h3>今日新增意向客户</h3>
          <div>
            <img src="../../assets/images/a.png" alt="" />
          </div> -->
          <div class="title-box" :v-model="infoData">
            <!-- <span>{{ infoData.value }}</span> -->
            <animate-number ref="reNum" from="0" :to="infoData.value ? infoData.value : 0" :key="infoData.value"
              duration="1000"></animate-number>
            <!-- <div>今日新增意向客户数</div> -->
            <div>意向客户</div>
          </div>
        </div>
        <div class="l2 zxxx">
          <!-- <h3>今日联络客户</h3>
          <div>
            <img src="../../assets/images/b.png" alt="" />
          </div> -->
          <div class="title-box">
            <!-- <span>{{ passivePhone }}</span> -->
            <animate-number ref="reNum" from="0" :to="passivePhone ? passivePhone : 0" :key="passivePhone"
              duration="1000"></animate-number>
            <!-- <div>今日联络客户数</div> -->
            <div>跟进客户</div>
          </div>
        </div>
      </header>
      <div v-else>
        <el-tabs type="border-card" @tab-click="countClick" v-model="activeCount">
          <el-tab-pane name="dataT">
            <span slot="label"><i class="el-icon-s-data"></i> 数据统计</span>
            <div class="adminBox">
              <div class="item-box" v-for="(item, i) in adminList" :key="i">
                <div class="box-top">
                  <div class="title">{{ item.title }}</div>
                  <el-tag :type="item.type">{{ item.titleTag }}</el-tag>
                </div>
                <div class="contetn-box">
                  <div class="title">
                    <div>
                      <!-- <animate-number ref="reNum" from="0" :to="adminForm[item.data] ? adminForm[item.data] : 0"
                        :key="adminForm[item.data]" duration="1000"></animate-number> -->
                      <h3>{{ adminForm[item.data] || 0 }}</h3>
                      <p class="annex" v-if="item.title == '预计续费金额'">待续企业数: {{ adminForm.y_money_count || 0 }} </p>
                      <p class="annex" v-if="item.title == '企业数'">锁定企业数: {{ adminForm.user_stop_count || 0 }} </p>
                      <p class="annex" v-if="item.title == '坐席数'">不活跃坐席数: {{ adminForm.stop_count || 0 }} </p>
                      <p class="annex" v-if="item.title == '坐席数'">虚拟坐席数: {{ adminForm.seat_count_xn || 0 }} </p>

                      <p class="annex" v-if="item.title == '账户余额'">个人套餐: {{ adminForm.gr_seatMoney || 0 }}</p>
                      <p class="annex" v-if="item.title == '账户余额'">公司套餐: {{ adminForm.gs_seatMoney || 0 }}</p>
                    </div>
                  </div>
                  <el-image style="width: 30px;height: 30px;" :src="item.src" fit="fill"></el-image>
                </div>
              </div>
              <div class="item-box">
                <div class="box-top">
                  <div class="title">上月创建坐席金额</div>
                  <el-tag type="warning"> ￥</el-tag>
                </div>
                <div class="contetn-box">
                  <div class="title">
                    <div>
                      <!-- <animate-number from="0" :to="oldSumForm.sum" duration="1000"
                        v-if="oldSumForm.sum"></animate-number> -->
                      <h3>{{ oldSumForm.sum || 0 }}</h3>
                      <p class="annex">套餐外消费金额: {{ oldSumForm.sum_seatWai || 0 }} </p>
                      <p class="annex">上月退还套餐金额: {{ oldSumForm.sum_returnSeatMoney || 0 }} </p>
                      <p class="annex">上月创建坐席金额-负企业: {{ oldSumForm.sum_noMoney || 0 }} </p>
                    </div>
                  </div>
                  <el-image style="width: 30px;height: 30px;" :src="oldSunSrc" fit="fill"></el-image>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="package">
            <span slot="label"><i class="el-icon-s-claim"></i> 套餐统计</span>
            <div class="adminBox">
              <div class="item-box" style="width: 18%;padding:6px;cursor: pointer;" @click="toCompany(item)"
                v-for="(item, i) in seatRateArr" :key="i">
                <div class="box-top">
                  <div class="title"><span style="font-weight: bold;font-size: 16px;">{{ item.seat_rate }}</span>套餐 </div>
                  <el-tag>个</el-tag>
                </div>
                <div class="contetn-box">
                  <div class="title">
                    <span style="font-size: 14px;">已开通</span>{{ item.count }}<span style="font-size: 14px;">个</span>
                    <br>
                  </div>
                  <el-image style="width: 30px;" :src="packageSrc" fit="fill"></el-image>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <main class="main-box" v-if="hiddenMenu">
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="roleName != '管理员'">
          <el-tab-pane label="新增客户详情" name="xzkhxq">
            <clientsCharts ref="xzkhxq" @sendYXKh="getYXKh" v-if="activeName == 'xzkhxq'"></clientsCharts>
          </el-tab-pane>
          <el-tab-pane label="通话排名" name="thpm">
            <el-empty v-if="chartsArrNull" description="查询通话排名成功,暂无通话数据"></el-empty>
            <div v-else id="chartColumn" v-loading="loading" element-loading-text="拼命加载中,请稍等..."
              style="height: 400px; width: 100%"></div>
          </el-tab-pane>
          <el-tab-pane label="意向客户排名" name="yxkhpm">
            <clientsRanking ref="yxkhpm" v-if="activeName == 'yxkhpm'"></clientsRanking>
          </el-tab-pane>
          <el-tab-pane label="业绩排名" name="yjpm">
            <performanceRanking ref="yjpm" v-if="activeName == 'yjpm'"></performanceRanking>
          </el-tab-pane>
          <!-- <el-tab-pane label="动态排序" name="dtpx">
            <bar-chart  v-if="activeName == 'dtpx'"></bar-chart>
          </el-tab-pane> -->
        </el-tabs>
        <div v-else>
          <userStatistics ref="userStatistics" :adminForm="adminForm" v-show="activeCount == 'dataT'"></userStatistics>
          <div id="chartColumn" style="min-height: 400px; width: 100%" v-show="activeCount == 'package'">
          </div>
        </div>
      </main>

    </el-card>
    <!-- 公告 -->
    <el-dialog title="公告" v-model="annVisible" width="60%" :before-close="handleClose">
      <h1>尊敬的用户</h1>
      <div style="margin-top: 20px; font-size: 16px; line-height: 30px">
        <p>由于近期运营商严检电信网络诈骗，商机云特此提醒：</p>
        <p>
          1：如发现报备公司冒充国家机关，中字头国企，及其他商业机构等，立即下线，一经发现罚款5000元一个号码一次，并且上报相关部门，永久拉黑企业；
        </p>
        <p>
          2：不允许出现国家规定的信息买卖行为，话术中出现资料买卖行为，立即下线，并上报相关部门；
        </p>
        <p>
          3：报备企业实际拨打业务中，请勿说添加微信，及QQ等情况，务必按照报备业务话术来拨打，禁止出现与报备话术不符合的业务场景，且不得辱骂，态度不端正，业务虚假夸大等情况，被叫明显反感，拒绝再拨，必须挂电话，不得脏话，并拉黑该用户，不再进行二次拨打。
        </p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="annSumbit">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 套餐详情弹框 -->
    <el-dialog title="套餐详情" :visible.sync="packageVisible" width="50%">
      <el-table :data="packageArr" border header-cell-class-name="table-header" height="500">
        <el-table-column prop="id" label="公司ID" align="center"></el-table-column>
        <el-table-column prop="realname" label="公司名称" align="center" width="280"></el-table-column>
        <el-table-column prop="rate" label="费率" align="center"></el-table-column>
        <el-table-column prop="count" label="坐席数" align="center"></el-table-column>
      </el-table>
      <!-- <div style="text-align: right">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div> -->
    </el-dialog>

    <!-- 查看数据统计模态框 -->
    <div class="model" v-if="roleName == '管理员' && showModel" @click.stop="">
      <el-button class="getBtn" type="primary" plain @click="hiddenModel">查看数据统计</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import clientsRanking from "@/views/charts/clientsRanking.vue";
import performanceRanking from "@/views/charts/performanceRanking.vue";
import clientsCharts from "@/views/charts/clientsCharts.vue";
import userStatistics from "@/views/charts/userStatistics.vue";
import BarChart from '@/components/BarChart.vue';


import { userGetUserLogin, userCountLogin } from "@/api/user";
import { userSmsFindOne } from "@/api/user-sms";
import { tableUserSelectShell, tableSelectShell, tableDaytableAll } from "@/api/table";
import { phoneDaystatusAll } from "@/api/phone";
import { callGetPassivePhone } from "@/api/call";
import { oldSumSeatRate } from "@/api/seatPhone";

import * as echarts from "echarts";

export default {
  name: "home",
  components: { BarChart, clientsRanking, performanceRanking, clientsCharts, userStatistics },
  data() {
    return {
      oldSumForm: {},
      packageVisible: false,
      packageArr: [],
      activeCount: 'dataT',
      seatRateArr: [],
      showModel: true,
      adminForm: {},
      dataForm: {},
      oldSunSrc: require(`@/assets/miniImg/oldzuoxi.png`),
      packageSrc: require(`@/assets/miniImg/taocan.png`),
      adminList: [
        {
          title: '账户余额',
          titleTag: '￥',
          src: require(`@/assets/miniImg/yue.png`),
          data: 'gs_money',
          type: ''
        },
        {
          title: '预计续费金额',
          titleTag: '￥',
          src: require(`@/assets/miniImg/xufei.png`),
          data: 'y_money',
          type: 'success'
        },
        {
          title: '企业数',
          titleTag: '个',
          src: require(`@/assets/miniImg/kehu.png`),
          data: 'user_count',
          type: 'info'
        }
        , {
          title: '坐席数',
          titleTag: '个',
          src: require(`@/assets/miniImg/zuoxi.png`),
          data: 'seat_count',
          type: 'warning'
        }

      ],
      timer: null,
      adminInfo: {},
      loading: true,
      chartsArrNull: false,
      activeName: "xzkhxq",
      annVisible: false,
      infoData: [],
      chartColumn: null,
      sumCount: 0,
      count: 0,
      seatMoney: 0,
      realname: null,
      companyName: null,
      money: 0,
      minute: 0,
      seatSumCount: 0,
      passivePhone: 0,
      user_sumCount: 0,
      user_count: 0,
      user_minute: 0,
      rate: 0,
      seatRate: 0,
      note_count: 0,
      s_seatCount: 0,

    };
  },
  async created() {
    this.annON = localStorage.getItem("annON");
    this.roleName = localStorage.getItem("roleName");
    this.realname = localStorage.getItem("realname");
    this.companyName = localStorage.getItem("companyName");
    this.ms_name = localStorage.getItem("ms_username");
    if (this.roleName != '管理员') {
      this.getList();
      // this.getCharts();
      this.getListb();
      this.getListc();
      this.noteList();
      this.$nextTick(() => {
        if (this.annON != 1) {
          this.announcementList();
        }
      });

      if (this.roleName == "企业") {
        this.parentId = localStorage.getItem("id");
      } else {
        this.parentId = localStorage.getItem("parentId")
      }


    }
    // let res = await this.getLista();
    // let res1 = await this.drawColumnChart();
  },
  activated() {
    let parentId = localStorage.getItem("parentId")

    if (parentId == 25175) {
      this.$router.push('/client/creatNums');
    }
  },
  computed: {
    // 特定公司不要人员看到部分菜单
    hiddenMenu() {
      let arr = [15360];
      let parentId = localStorage.getItem("parentId") * 1
      // if (this.roleName != '企业' && arr.includes(parentId)) {
      // console.log("员工隐藏",parentId);
      // console.log("员工隐藏",this.roleName != '企业' && arr.includes(parentId));
      //   return false
      // } else {
      //   console.log("企业显示");
      //   return true
      // }
      return !(this.roleName != '企业' && arr.includes(parentId))
    }
  },
  mounted() {

    if (this.roleName != "企业") {
      this.$nextTick(() => {
        // this.$refs.reNum.start();
      });
    }
  },
  methods: {
    // 去公司管理
    async toCompany(value) {
      // seat_rate
      // this.$router.push({
      //   path: "/creatCompany/creatCompany",
      //   query: { //query是个配置项
      //     seat_rate: value.seat_rate
      //   }
      // })
      this.packageVisible = true
      let res = await userCountLogin({ seatRate: value.seat_rate });
      if (res.data.statusCode == "00000") {
        this.packageArr = res.data.data
      } else {
        Message.error(res.data.Message);
      }
    },
    countClick(tab) {
      console.log('tab', tab.paneName);

      if (tab.paneName == "package") {
        setTimeout(() => {
          this.rateColumnChart()
        }, 1000)
      }
    },
    rateColumnChart() {
      let sum = 0
      this.seatRateArr.forEach((v) => {
        sum += v.count
      })
      let chartColumn = echarts.init(document.getElementById("chartColumn"));
      chartColumn.setOption(
        {

          title: [
            {
              text: '套餐统计',
              subtext: '总计 ' + sum + '个',
              left: '50%',
              textAlign: 'center'
            }
          ],
          tooltip: {
            trigger: "item",
            formatter: "{b}  {c}分钟",
          },

          xAxis: {
            type: 'category',
            name: '套餐名称',
            data: this.seatRateArr.map((v) => {
              return v.seat_rate + "套餐"
            })
          },
          yAxis: {
            type: 'value',
            name: '套餐开通数/个'
          },
          series: [
            {
              data: this.seatRateArr.map((v) => {
                return v.count
              }),
              type: 'line',
              symbol: 'triangle',
              symbolSize: 20,
              lineStyle: {
                color: '#5470C6',
                width: 3,
                type: 'dashed'
              },
              itemStyle: {
                borderWidth: 5,
                borderColor: '#EE6666',
                color: 'yellow'
              },
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#3278FE",
                },
                formatter: function (params) {
                  return params.value + "个";
                },
              },
            }
          ]
        }
      )
    },
    async hiddenModel() {
      this.showModel = false
      await this.getList()
      //  this.adminForm
    },
    // 获取意向客户
    getYXKh(value) {
      this.infoData.value = value
      console.log("意向客户的值", value);
    },

    handleClick(tab, event) {
      if (this.timer) {
        Message.warning("请勿频繁切换~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        if (tab.paneName == 'thpm') {
          this.getCharts();
        }
        this.timer = null
      }, 500);
    },
    // 短信相关
    noteList() {
      let parentId = JSON.parse(localStorage.getItem("id"));
      let params = {
        parentId,
      };
      // let res = userSmsFindOne(params);
      // res.then((res) => {
      //   this.rate = res.data.data.rate;
      //   this.seatRate = res.data.data.seatRate;
      //   this.s_seatCount = res.data.data.seatCount;
      //   this.note_count = res.data.data.count;
      // });
    },
    // 公告关闭
    handleClose(done) {
      this.$confirm("确认公告已关闭")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    // 公告确认
    annSumbit() {
      this.annON = localStorage.setItem("annON", 1);
      // console.log(222311111111);
      this.annVisible = false;
    },
    // 公告
    announcementList() {
      this.annVisible = true;
    },
    // echarts
    drawColumnChart() { // 管理员
      // console.log("标题", this.titleArr);
      // console.log("值", this.valueArr);
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));

      this.chartColumn.setOption(
        {
          // title: { text: "通话排名" },
          dataZoom: [
            {
              type: 'slider',
              show: true,
              start: 0,
              end: 9,
              xAxisIndex: [0],
            },
          ],
          tooltip: {},
          color: ["#3278FE", "#00D6E1", "#409597"],
          legend: {
            // orient: "vertical",
            // left: "top",
            data: ["通话时长/秒", "今日通话/次", "接通数/次"],
          },
          xAxis: {
            //   interval: 0, //去除间隙  刻度间隔为0
            data: this.titleArr,
            name: "姓名",
            //   data: [12, 232, 123, 123, 12, 3, 123, 1, 23, 123, 1, 231],
          },
          yAxis: [
            {
              type: "value",
              name: "通话时长/今日通话/接通数",
              position: "left",
              alignTicks: true,
              axisLine: {
                show: true,
              },
            },
            // data: this.valueArr,
          ],
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              // debugger;
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于3,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }
                return ret;
              } else {
                return value;
              }
            },
          },
          series: [
            {
              name: "通话时长/秒",
              type: "bar",

              barMaxWidth: "80",
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#3278FE",
                },
                formatter: function (params) {
                  return params.value + "秒";
                },
              },
              animationDelay: function (idx) {
                return idx * 10;
              },
              emphasis: {
                focus: "series",
              },
              data: this.valueArr,
            },
            {
              name: "今日通话/次",
              type: "bar",

              barMaxWidth: "80",
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#00D6E1",
                },
                formatter: function (params) {
                  return params.value + "次";
                },
              },
              animationDelay: function (idx) {
                return idx * 10 + 100;
              },
              emphasis: {
                focus: "series",
              },
              data: this.valueArra,
            },
            {
              name: "接通数/次",
              type: "bar",

              barMaxWidth: "80",

              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#409597",
                },
                formatter: function (params) {
                  return params.value + "次";
                },
              },
              animationDelay: function (idx) {
                return idx * 10 + 200;
              },
              emphasis: {
                focus: "series",
              },
              data: this.valueArrb,
            },
          ],
          animationEasing: "elasticOut",
          animationDelayUpdate: function (idx) {
            return idx * 5;
          },
        });
    },
    async getOldSumSeatRate() {
      let res = await oldSumSeatRate({});
      if (res.data.statusCode == "00000") {
        this.oldSumForm = res.data.data
        console.log("显示666", this.oldSumForm);
      } else {
        Message.error(res.data.Message);
      }
      console.log("res", res);
    },
    // 展示
    getList() {
      // 数据统计
      let lastIp = sessionStorage.getItem("ip");
      let params = {
        // ip: lastIp,  // 暂时不传IP
      };
      this.getOldSumSeatRate()
      console.log("传ip", lastIp);
      let res = userGetUserLogin(params);
      res.then((res) => {
        this.adminForm = res.data.data
        console.log("this.adminForm", this.adminForm);
        this.money = res.data.data.money;
        this.seatMoney = res.data.data.seatMoney;
        this.seatSumCount = res.data.data.seatSumCount;
        this.remark = res.data.data.remark;
        this.adminInfo = res.data.data;
      });

      // 套餐统计
      if (!this.showModel) {
        let res1 = userCountLogin({});
        res1.then((res1) => {
          if (res1.data.statusCode == "00000") {
            console.log("res1.data", res1.data.data);
            this.seatRateArr = res1.data.data
          }
        });
      }


    },
    // 视图展示
    async getCharts() {
      let params = {
        userId: this.parentId
      };
      let data;
      // let resUser = await tableUserSelectShell(params);
      // data = resUser.data.data;
      // data.map((v, i) => {
      //   this.user_sumCount = v.sumCount != null ? v.sumCount : 0;
      //   this.user_count = v.count != null ? v.count : 0;
      //   this.user_minute = v.minute != null ? v.minute : 0;
      // });

      let resFirm = await tableDaytableAll(params);

      data = resFirm.data.data;
      if (data.length == 0) {
        this.chartsArrNull = true;
      }
      console.log("统计数据", data);
      let sumCount = 0;
      let count = 0;
      let minute = 0;
      let userId = localStorage.getItem("id");

      data = data.filter((v) => v.seconds);


      data.forEach((v, i) => {
        if (v.userId == userId) {
          this.user_sumCount = v.sumCount != null ? v.sumCount : 0;
          this.user_count = v.count != null ? v.count : 0;
          this.user_minute = v.minute != null ? v.minute : 0;
        }
        sumCount += v.sumCount;
        count += v.count;
        minute += v.minute;
      });
      this.sumCount = sumCount;
      this.count = count;
      this.minute = minute;
      this.titleArr = [];
      this.valueArr = [];
      this.valueArra = [];
      this.valueArrb = [];
      data.sort((a, b) => b.seconds - a.seconds);
      let newArr = data.map((v) => {
        return { title: v.name, value: v.seconds };
      });
      let newArra = data.map((v) => {
        return { title: v.name, value: v.sumCount };
      });
      let newArrb = data.map((v) => {
        return { title: v.name, value: v.count };
      });
      newArr.forEach((v) => {
        this.titleArr.push(v.title);
        this.valueArr.push(v.value);
      });
      console.log("newArr", newArr);
      newArra.forEach((v) => {
        this.valueArra.push(v.value);
      });
      newArrb.forEach((v) => {
        this.valueArrb.push(v.value);
      });
      this.$nextTick(() => {
        this.drawColumnChart();
      });
    },
    async getListb() {
      let params = {};
      let res = await phoneDaystatusAll(params);
      let arr = [];
      for (let i = 0; i <= 6; i++) {
        arr.push({ id: i, value: res.data.data[i] || 0 });
      }
      this.infoData = arr[1];
      // console.log("arr数组", this.infoData.value);
    },
    async getListc() {
      let params = {};
      let res = await callGetPassivePhone(params);
      this.passivePhone = res.data;
    },
  },
  // updated: async function () {
  //   let res = await this.drawColumnChart();
  // },
};
</script>

<style scoped lang="less">
.model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);

  .getBtn {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
  }
}

.adminBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.adminBox>.item-box {
  width: 19%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.adminBox>.item-box>.box-top {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 4px;
  box-sizing: border-box;
  border-bottom: 1px solid #ECEFF5;
}

.item-box>.box-top>.title {
  display: flex;
  align-items: center;
}

.adminBox>.item-box>.contetn-box {
  padding: 20px 5px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.item-box>.contetn-box .annex {
  font-size: 12px;
  color: #f2ae43;
  margin-top: 6px;
}

.item-box>.contetn-box>.title {
  // display: flex;
  // align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.container {
  height: 100%;
  overflow-y: scroll;
}

/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

header {
  margin-bottom: 30px;

  @keyframes example {
    0% {
      background-size: 100px 100px;
    }

    // 25%  {background-color: yellow;}
    // 50%  {background-color: blue;}
    100% {
      background-size: 130px 130px;
    }
  }

  .zxxx {
    position: relative;
    // background-image: ;
    color: #333;
    // background-color: #999;
    background-repeat: no-repeat;
    background-position: 90% 40%;
    background-size: 100px 100px;
  }

  .c2 {
    // background-color: #2d8cf0;
    background-image: url("../../assets/images/c4.png");
  }

  .t2 {
    // background-color: #19be6b;
    background-image: url("../../assets/images/t4.png");
  }

  .d2 {
    // background-color: #ff9900;
    background-image: url("../../assets/images/d2.png");
  }

  .x2 {
    // background-color: #ed3f14;
    background-image: url("../../assets/images/x4.png");
  }

  .l2 {
    // background-color: #e46cbb;
    background-image: url("../../assets/images/l4.png");
  }

  .zxxx:hover {
    // background-size: 150px 150px !important;
    opacity: 0.8;
    color: #333;
    animation-name: example;
    animation-duration: 1s;
    background-size: 130px 130px;
  }

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left !important;

    &>span {
      position: absolute;
      left: 20%;
      bottom: 40%;
      font-size: 3em;
    }

    &>div {
      position: absolute;
      bottom: 10%;
      font-size: 20px;
    }
  }
}

.main-box {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 2px 13px 7px rgba(116, 242, 245, 0.3);
  margin-top: 10px
}

header,
header>div>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header>div>div>img {
  width: 50px;
  height: 50px;
}

header>div>div {
  margin: 20px 0;
  font-size: 18px;
  // color: red;
}

/* header > div > div span { */
/* } */
header>div {
  padding: 20px;
  color: #fff;
  box-sizing: border-box;
  background-color: #fff;
  width: 23%;
  height: 200px;
  border-radius: 6px;
}

header>div span {
  font-weight: bold;
}

header>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 13px 7px rgba(116, 242, 245, 0.3);
}

header>div>p {
  width: 100%;
  text-align: left;
  // margin-left: 50%;
}

header>div>h3 {
  /* font-weight: normal; */
  color: #5b5b5b;
}

header>div>.company-name {
  margin-bottom: 8px;
  font-size: 14px;

  span {
    font-size: 12px;
  }
}
</style>